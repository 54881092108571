import React, { useEffect } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { PageTitle } from '../PageTitle';
import { AppState } from '../../store/configureStore';
import { connect, useSelector } from 'react-redux';
import { Health } from '../../definitions/Claims';
import InformedChoice from '../cards/HealthFlexBenefits/InformedChoice';
import HealthAccounts from '../cards/HealthFlexBenefits/HealthAccounts';
import LearnMore from '../cards/HealthFlexBenefits/LearnMore';
import WhatsNewVideo from '../cards/HealthFlexBenefits/WhatsNewVideo';
import ChangeBenefits from '../cards/HealthFlexBenefits/ChangeBenefits';
import AeBanner from '../cards/HealthFlexBenefits/AeBanner';
import ReviewPastClaims from '../cards/HealthFlexBenefits/ReviewPastClaims';
import LearnAbout2Desktop from '../cards/HealthFlexBenefits/LearnAbout2Desktop';
import LearnAbout from '../cards/HealthFlexBenefits/LearnAbout';
import MDLiveChange from '../cards/HealthFlexBenefits/MDLiveChange';

type THealthFlexBenefitsProps = ReturnType<typeof mapStateToProps>;

const HealthFlexBenefitsPage = (props: THealthFlexBenefitsProps) => {
    const { hasClaim } = props;

    useEffect(() => {
        document.title = "Benefits Access - Health Flex Benefits"
    }, []);

    
    const preEnrollmentPeriod = useSelector((state: AppState) => state.healthPlans?.benefitSolverHeader?.showPreEnrollmentBanner ?? false)
    const enrollmentPeriod = useSelector((state: AppState) => state.healthPlans?.benefitSolverHeader?.showEnrollmentBanner ?? false)

    const showAEContent = preEnrollmentPeriod || enrollmentPeriod

    const formatter = new Intl.DateTimeFormat('en-US', { dateStyle: 'short' }); // Adjust format as needed
    const formattedDate = formatter.format(new Date());

    const currentDate = new Date(formattedDate);
    const futureDate = new Date("12/31/2024") as Date; // Dec 31, 2024

    const doNotShowMDLiveComp =  currentDate > futureDate ;

    if (!hasClaim) {
        return <></>;
    }

    const DesktopView = () => (
        <Card className="detailCard desktop" style={{ marginTop:'2em' }}>
            <PageTitle title="HealthFlex Benefits" color="health-color-bg" hidePrintButton />
            <Card.Body className="pt-4">
                {showAEContent && <><WhatsNewVideo />
                    <Row>
                        <Col className="col-12 col-lg-6">
                            <LearnAbout />
                        </Col>
                        <Col className="col-12 col-lg-6 learnAbout">
                            <LearnAbout2Desktop />
                        </Col>
                    </Row>
                    <hr className="mb-5" style={{
                    borderBottom: 'solid 1px #C4C4C4', borderTop:'1px', marginLeft:'2.5rem',width:'93%'
                    }} />
                </>}
                <Row>
                    <Col className="col-12 col-lg-6">
                        {showAEContent ? <InformedChoice /> : <ChangeBenefits />}
                        <HealthAccounts />
                        {showAEContent && <LearnMore />}
                    </Col>
                    <Col className="col-12 col-lg-6">
                        {!doNotShowMDLiveComp && <MDLiveChange />}
                        {showAEContent ? <ReviewPastClaims /> : <LearnMore />}
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );

    const MobileView = () => (
        <div className="detailCard mobile">
            <Card>
                <PageTitle title="HealthFlex Benefits" color="health-color-bg" hidePrintButton />
                <Card.Body>
                    {showAEContent && <WhatsNewVideo />}
                    {showAEContent && <LearnAbout />}
                    {showAEContent ? <InformedChoice /> : <ChangeBenefits />}
                    {!doNotShowMDLiveComp && <MDLiveChange />}
                    {showAEContent && <ReviewPastClaims />}
                    <HealthAccounts />
                    <LearnMore />
                </Card.Body>
            </Card>
        </div>
    );

    return <>

        {enrollmentPeriod && <AeBanner />}

        <DesktopView />

        <MobileView />

    </>
};
function mapStateToProps(state: AppState) {
    return {
        hasHsa: state.hsa.hsa?.healthSavingsAccounts?.length > 0,
        hasClaim: state.claims.claims?.claims.find(c => c === Health.View)
    }
}

export default connect(
    mapStateToProps
)(HealthFlexBenefitsPage);
